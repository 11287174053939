<template>
  <section id="contact">
    <PageTitle title="Contact Me" icon="mdi-email-outline"/>
    <v-container>
      <h3 class="title text-center">FEEL FREE TO ASK ME ANYTHING!</h3>
      <v-form class="center-form">
        <!-- Name -->
        <v-text-field
          dark
          color="primary"
          label-color="error"
          label="Name"
          v-model="User.name"
          class="max-form-width"
        ></v-text-field>
        <!-- Email -->
        <v-text-field
          dark
          color="primary"
          label="Email"
          v-model="User.email"
          class="mt-3 max-form-width"
        ></v-text-field>
        <!-- Phone -->
        <v-text-field
          dark
          color="primary"
          label="Phone"
          v-model="User.phone"
          class="mt-3 max-form-width"
        ></v-text-field>
        <!-- Subject -->
        <v-text-field
          dark
          color="primary"
          label="Subject"
          v-model="User.subject"
          class="mt-3 max-form-width"
        ></v-text-field>
        <!-- Message -->
        <v-textarea
          name="input-msg"
          outlined
          dark
          color="primary"
          label="Message"
          v-model="User.message"
          class="mt-5 max-form-width"
        ></v-textarea>
        <!-- Send -->
        <div class="text-center">
          <v-btn
            disabled
            v-if="
              User.name.length === 0 ||
              User.email.length === 0 ||
              User.subject.length === 0 ||
              User.email.length === 0 ||
              User.message.length === 0
            "
            class="mt-3 px-16 py-6 btn-primary"
          >SEND</v-btn>
          <v-btn
            v-if="
            User.name.length > 0 &&
            User.email.length > 0 &&
            User.subject.length > 0 &&
            User.email.length > 0 &&
            User.message.length > 0
          "
            class="mt-3 px-16 py-6 btn-primary"
            @click="sendEmail()"
          >SEND</v-btn>
        </div>
      </v-form>
    </v-container>
  </section>
</template>

<script>
import PageTitle from '@/components/shared/PageTitle.vue';

export default {
  name: 'contact',
  components: {
    PageTitle,
  },
  data: () => ({
    User: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    },
    Alert: {
      show: false,
    },
  }),
  methods: {
    sendEmail() {
      this.clearInputs();
    },
    isEmpty(str) {
      return (!str || str.length === 0);
    },
    clearInputs() {
      this.User = {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .center-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .max-form-width {
    width: 100%;
    max-width: 800px;
  }
</style>
